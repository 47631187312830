@use '~@angular/material' as mat;
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '~@angular/material/theming';
@import './assets/styles/index.scss';
$mat-brand-red: (50: #ffebee,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: #f44336,
  600: #e53935,
  700: #d32f2f,
  800: #c62828,
  900: #b71c1c,
  A100: #ff8a80,
  A200: #ff5252,
  A400: #ff1744,
  A700: $brand-color,
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ));
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$candy-app-primary: mat.define-palette(mat.$grey-palette, 700);
$app-primary: mat.define-palette($mat-brand-red, A700, A100, A400);
$form-primary: mat.define-palette(mat.$indigo-palette, A700);
$candy-app-accent: mat.define-palette(mat.$grey-palette, 300, 200, 100);

// The warn palette is optional (defaults to red).
$candy-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$candy-app-theme: mat.define-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);
$app-theme: mat.define-light-theme($app-primary, $candy-app-accent, $candy-app-warn);
$form-theme: mat.define-light-theme($form-primary, $candy-app-accent, $candy-app-warn);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);
@include mat.tabs-theme($candy-app-theme);
@include mat.form-field-theme($form-theme);

// $brand-color: mat-color($mat-brand-red, A700);

.brand-color {
  color: mat.get-color-from-palette($mat-brand-red, A700);
}

mat-form-field {

  input:focus,
  textarea:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
}

.mat-tab-label,
.mat-tab-link {
  &.mat-tab-label-active {
    opacity: 1;
  }
}

.tgpp-new-review-container {
  @include mat.tabs-theme($app-theme);

  .mat-tab-label {
    color: $brand-color;

    &.mat-tab-label-active {
      opacity: 1;
    }
  }
}

.tgpp-full-width {
  width: 100%;
}

.tgpp-half-width {
  width: 50%;
}

.tgpp-float-left {
  float: left;
}

.link-color{
  color:$link-color
}


@media print {
  .tgpp-print-hide {
    display: none !important;
  }
}

.help-legal-reviewer {
  cursor: pointer;
}